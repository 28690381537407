import { faServer } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { numberFormat } from "../../assets/js/utils";

const departments = {
    "special": 'مزاد أرقام الهواتف ولوحات السيارات المميزة',
    "devices": 'مزاد الاجهزة المنزليه الكهربائية',
    "mobile": 'مزاد الجوالات والهواتف',
    "car": 'مزاد السيارات والشاحنات',
    "equipment": 'مزاد المعدات الصناعية',
    "goods": 'مزاد بضائع متنوعة',
    "real_estate": 'مزاد العقارات',
}

const cardDetails = item => {

    const sub = item?.main_subpivot[item?.main_subpivot.type];

    return (
        [
            { name: "نوع الاصل", value: departments[item?.main_subpivot.type], icon: faServer },
            { name: "مبلغ العربون", value: numberFormat(sub?.deposit_amount), icon: faServer },
            { name: "مبلغ الأفتتاح", value: numberFormat(sub?.opening_amount), icon: faServer },
            { name: "الحد الادني", value: numberFormat(sub?.minimum_amount), icon: faServer },
            { name: "الموقع", value: sub?.map_link, icon: faServer, asLink: true },
        ]
    )

}

const renderCardDetails = item => cardDetails(item).map(card => {

    return (
        <div className='flex items-center mb-4'>

            <div className='w-[21px] h-[21px] flex items-center justify-center bg-[#F0F0F0] rounded-[3px] me-3'>

                <FontAwesomeIcon icon={faServer} className='text-[#9F9F9F] text-[13px]' />

            </div>

            <h3 className='font-medium text-[15px]'>
                {card?.name}
                <span className='mx-1'>:</span>
                {
                    card.asLink
                        ?
                        <a href={card.value} className="underline">زياره الموقع</a>
                        :
                        <span>{card.value}</span>

                }
            </h3>

        </div>
    )

});

export {
    renderCardDetails
}
import { isLogin } from "../../assets/js/utils";
import { Breadcrumb } from "../../layouts";

export default function MainContent({ isExpanded, isRenderHeader, children }) {

    const isLoggedIn = isLogin();

    return (
        <div className={`p-5 bg-[#ffffff] mt-[60px] ${isExpanded && isRenderHeader && isLoggedIn ? "lg:ms-[300px]" : ""}`} style={{ transition: "0.5s" }}>

            <div className='container'>

                {isRenderHeader && isLoggedIn ? <Breadcrumb /> : null}

                {children}

            </div>

        </div>
    )
}

import { handleLogOut } from "../../assets/js/utils";
import { baseUrl, RequestManager, Swal } from "../data";

class analytical {

    getAnalytical(state) {

        return RequestManager.get(`${baseUrl}analytical`).then(response => {

            return state(response.data?.Analytical);

        }).catch(error => {

            Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

        });

    }

}

export {
    analytical
}

import { Header, MainPanel } from './layouts';
import useCustomEffect from './hooks/useCustomEffect';
import routes from './routes/routes';
import { Auth, ScrollReset, ScrollToTopBtn } from './components';
import Loader from './pages/Loader';
import './assets/style/App.css';
import "aos/dist/aos.css";
import { useContext, useEffect } from 'react';
import { AppContext } from './components/AppContext/AppContext';
import { isLogin, userPermissions } from './assets/js/utils';


function App() {

  const { useAppControlar } = useCustomEffect();

  const { isRenderLayouts, isExpanded, setIsExpanded, setIsRenderLayouts } = useAppControlar();

  const isLoggedIn = isLogin();

  const { isLoading, user } = useContext(AppContext);

  const isMemmber = userPermissions(user);

  useEffect(() => {

    const body = document.body;

    if (isLoading) {

      body.style.cssText += "max-height: 100vh; overflow:hidden";

    } else {

      body.style.cssText += "max-height: initial; overflow:initial";

    }

  }, [isLoading]);

  return (

    <div className="App">

      {isLoading ? <Loader /> : null}

      {/* <Auth /> */}

      {isRenderLayouts && isLoggedIn ? <Header isExpanded={isExpanded} setIsExpanded={setIsExpanded} /> : null}

      {isRenderLayouts && isLoggedIn ? <MainPanel isExpanded={isExpanded} setIsExpanded={setIsExpanded} /> : null}

      <ScrollReset />

      <ScrollToTopBtn />

      {routes(isMemmber, isExpanded, isRenderLayouts, setIsRenderLayouts, isLoggedIn)}

    </div>
  );
}

export default App;

import { faImage } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { renderCardDetails } from './data';

export default function OriginCard({ classNames, item }) {

    const [activeBg, setActiveBg] = useState(0);

    return (

        <div className={`p-2 border border-[#9F9F9F] rounded-[10px] ${classNames}`}>

            <a href={`https://abram.com.sa/auctions/auction-details?id=${item?.main_auction_id || item?.main_subpivot?.main_auction_id}`} target='_blank' className='relative h-[252px] mb-20 block rounded-[10px]'>

                <img
                    src={item?.main_subpivot[item?.main_subpivot?.type]?.images[0]
                        || require('./bg.png')}
                    alt=''
                    onError={(e) => {
                        e.target.src = require('./bg.png');
                    }}
                    className='object-fill rounded-[10px] w-full h-full absolute left-0 top-0 z-0'
                />

                <div className='flex items-center justify-between relative z-10'>

                    <div className='flex items-center p-[0.8px] mt-1 ms-1 border border-[#c7c6c6cc] bg-[#c7c6c6cc] rounded-[5px]'>

                        <div onClick={_ => setActiveBg(0)} className={`flex items-center justify-center p-2 w-[40px] h-[26px] cursor-pointer text-white ${activeBg === 0 ? "bg-[#71C4F0] rounded-[3px]" : ""}`}>

                            <FontAwesomeIcon icon={faImage} />

                        </div>
                        <div onClick={_ => setActiveBg(1)} className={`flex items-center justify-center p-2 w-[40px] h-[26px] cursor-pointer text-white ${activeBg === 1 ? "bg-[#71C4F0] rounded-[3px]" : ""}`}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M14 6.66669C14 11.3334 8 15.3334 8 15.3334C8 15.3334 2 11.3334 2 6.66669C2 5.07539 2.63214 3.54926 3.75736 2.42405C4.88258 1.29883 6.4087 0.666687 8 0.666687C9.5913 0.666687 11.1174 1.29883 12.2426 2.42405C13.3679 3.54926 14 5.07539 14 6.66669Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 8.66669C9.10457 8.66669 10 7.77126 10 6.66669C10 5.56212 9.10457 4.66669 8 4.66669C6.89543 4.66669 6 5.56212 6 6.66669C6 7.77126 6.89543 8.66669 8 8.66669Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>

                </div>

                <div className='p-3 absolute bottom-[-50px] z-10 w-full'>

                    <div className='flex items-center justify-between mb-3'>

                        <h3 className='text-[15px] flex items-center text-white'>

                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                <path d="M13.125 7.25C13.125 11.625 7.5 15.375 7.5 15.375C7.5 15.375 1.875 11.625 1.875 7.25C1.875 5.75816 2.46763 4.32742 3.52252 3.27252C4.57742 2.21763 6.00816 1.625 7.5 1.625C8.99184 1.625 10.4226 2.21763 11.4775 3.27252C12.5324 4.32742 13.125 5.75816 13.125 7.25Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 9.125C8.53553 9.125 9.375 8.28553 9.375 7.25C9.375 6.21447 8.53553 5.375 7.5 5.375C6.46447 5.375 5.625 6.21447 5.625 7.25C5.625 8.28553 6.46447 9.125 7.5 9.125Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <span className='ms-2'>{item?.Location_country}</span>

                        </h3>

                        <h3 className='text-[15px] text-white'>{item?.Location_city}</h3>

                    </div>

                    <div className='flex items-center justify-around'>

                        <div className="num-container">
                            <h1 className="text-white font-medium">{item?.main_subpivot?.id}</h1>
                            <p className="font-medium">رقم الاصل</p>
                        </div>

                        <h1 className='flex-1 p-3 mx-5 rounded-[10px] text-center bg-[#E2EFFF] text-[30px]'>
                            {item?.main_subpivot[item?.main_subpivot.type].title}
                        </h1>


                    </div>

                </div>

            </a>

            <div className='mb-10'>

                {renderCardDetails(item)}

            </div>

            <div className='flex items-center'>

                <a href={`https://abram.com.sa/auctions/auction-details?id=${item?.main_auction_id || item?.main_subpivot?.main_auction_id}`} target='_blank' className='text-white font-medium bg-[var(--secondary)] p-3 text-center flex-1 rounded-[5px] me-2'>التفاصيل</a>

            </div>

        </div>
    )
}

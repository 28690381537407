import './loader.css';

export default function Loader({ classNames }) {

    return (
        <div className={`w-[100vw] h-[100vh] left-0 top-0 bg-[#6AA9A1] fixed flex items-center justify-center z-[2000] ${classNames}`}>

            <span className="page-loader"></span>

        </div>
    )
}

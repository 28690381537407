import { faSlideshare } from "@fortawesome/free-brands-svg-icons";
import { faBlog, faCrown, faDiagramPredecessor, faGear, faKey, faNetworkWired, faPen, faPlus, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { userPermissions } from "../../../../assets/js/utils";

const isMemmber = user => userPermissions(user);

const getRoutes = (user) => {

    return isMemmber(user)
        ?
        [
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <FontAwesomeIcon icon={faGear} className={`!text-black`} />

                    <span className='ms-4 text-[#303D45]'>الإعدادات</span>
                </div>,
                items: [
                    {
                        label: <NavLink to={'profile'} end className='flex items-center  p-2 !w-full'>

                            <FontAwesomeIcon icon={faPen} className="text-[18px]" />

                            <span className='ms-3'>الملف الشخصي</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'change-password'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faKey} className="text-[18px]" />

                            <span className='ms-3'>تغير كلمة المرور</span>
                        </NavLink>,

                    },
                ]
            },
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <FontAwesomeIcon icon={faSitemap} className={`!text-black`} />

                    <span className='ms-4 text-[#303D45]'>المزادات</span>
                </div>,
                items: [
                    {
                        label: <NavLink to={'auctions'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faDiagramPredecessor} className="text-[18px]" />

                            <span className='ms-3'>المزادات</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'add-auction'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faPlus} className="text-[18px]" />

                            <span className='ms-3'>إدراج مزاد</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'auctions-awaiting-publication'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faBlog} className="text-[18px]" />

                            <span className='ms-3'>مزادات فى انتظار النشر</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'deals'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faNetworkWired} className="text-[18px]" />

                            <span className='ms-3'>الصفقات</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'shared-auctions'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faSlideshare} className="text-[18px]" />

                            <span className='ms-3'>مزادات شاركت بها</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'favorite-auctions'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faCrown} className="text-[18px]" />

                            <span className='ms-3'>المزادات المفضلة</span>
                        </NavLink>,

                    },
                ]
            }

        ]
        :
        [
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <FontAwesomeIcon icon={faGear} className={`!text-black`} />

                    <span className='ms-4 text-[#303D45]'>الإعدادات</span>
                </div>,
                items: [
                    {
                        label: <NavLink to={'profile'} end className='flex items-center  p-2 !w-full'>

                            <FontAwesomeIcon icon={faPen} className="text-[18px]" />

                            <span className='ms-3'>الملف الشخصي</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'change-password'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faKey} className="text-[18px]" />

                            <span className='ms-3'>تغير كلمة المرور</span>
                        </NavLink>,

                    },
                ]
            },
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <FontAwesomeIcon icon={faSitemap} className={`!text-black`} />

                    <span className='ms-4 text-[#303D45]'>المزادات</span>
                </div>,
                items: [
                    {
                        label: <NavLink to={'deals'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faNetworkWired} className="text-[18px]" />

                            <span className='ms-3'>الصفقات</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'shared-auctions'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faSlideshare} className="text-[18px]" />

                            <span className='ms-3'>مزادات شاركت بها</span>
                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'favorite-auctions'} className='flex items-center  p-2 !w-full' end>

                            <FontAwesomeIcon icon={faCrown} className="text-[18px]" />

                            <span className='ms-3'>المزادات المفضلة</span>
                        </NavLink>,

                    },
                ]
            }

        ];

}


export const items = user => getRoutes(user);
import { useEffect, useState } from 'react';
import { Auth, NotificationService } from '../../apis/apis';


const useDataGetter = _ => {
    const [visible, setVisible] = useState(false);

    const [data, setData] = useState({});

    const [logoutVisible, setLogoutVisible] = useState(false);

    const notficaitionsUtailty = new NotificationService();

    useEffect(() => {

        notficaitionsUtailty.fetchNotifications(setData);

    }, []);

    useEffect(() => {

        if (data?.totalNullReadAt && visible) {

            notficaitionsUtailty.updateNotifications().then(_ => data.totalNullReadAt = 0);

        }

        return () => { }

    }, [visible])

    useEffect(() => {

        const closePopup = () => {
            setVisible(false);
            setLogoutVisible(false);
        };

        window.addEventListener('click', closePopup);

        // Clean Up
        return () => window.removeEventListener('click', closePopup);

    }, []);

    const logOut = () => {

        const authUtailty = new Auth();

        authUtailty.postLogout().then(_ => {

            localStorage.removeItem('token');

            return window.location.href = "https://abram.com.sa/";

        });

    }

    return {
        logoutVisible,
        visible,
        setVisible,
        setLogoutVisible,
        data,
        logOut
    }
}

export {
    useDataGetter
}
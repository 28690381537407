import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function EditableItemControls({ handleDelete, classNames }) {
    return (
        <div className={`flex items-center justify-end ${classNames}`}>

            <div className='flex items-center'>

                <button type='submit' className='rounded-full text-white bg-[var(--secondary)] p-[4px] px-8 font-medium'>حفظ التعديل</button>

                <button type='button' onClick={handleDelete} className='rounded-full text-white bg-[var(--secondary)] w-[40px] h-[40px] font-medium ms-5'>

                    <FontAwesomeIcon icon={faTrash} />

                </button>

            </div>

        </div>
    )
}

import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, baseUrl, Swal } from "../data";

class ConfirmEmail {

    getOtp() {

        return RequestManager.post(`${baseUrl}verify-email/send-otp`, null, true).then(response => {

            return response;

        })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })
    }

    postOtp(otp, setVisible) {

        return RequestManager.post(`${baseUrl}verify-email/store-otp`, { otp }, true).then(response => {

            return Swal.success('تم ادخال رمز صحيح').then(_ => setVisible(false));

        }).catch(error => {

            Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

        })
    }

}

export {
    ConfirmEmail
}
import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class ProfileManger {

    fetchProfile(state) {

        return RequestManager.get(`${baseUrl}auth/profile`)

            .then(response => {

                state({ ...response.data?.Profile, ...response.data?.SocialMedia, verifyEmail: response?.data?.verifyEmail, verifyPhone: response.data?.verifyPhone });

                return response.data;

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }
    userInfo(setUser) {

        return RequestManager.get(`${baseUrl}information`)

            .then(response => {

                if (response.data?.InformationServices?.nafath !== 'COMPLETED') {

                    return window.location.href = 'https://abram.com.sa/confrimation';

                }

                setUser && setUser(response.data?.InformationServices);
                // Set User Info To SessionStorage
                sessionStorage.setItem('user', JSON.stringify(response.data?.InformationServices));

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

    editProfile(data) {

        this.setLoader && this.setLoader(true);

        return RequestManager.update(`${baseUrl}auth/profile/update`, data, null, true)

            .then(response => {

                return Swal.success('تم بنجاح!', response.data?.message);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

    changePassword(data) {

        return RequestManager.update(`${baseUrl}auth/change-password`, data, 'PUT')

            .then(response => {

                Swal.success('Updated!', response.data?.message);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })


    }

}

export {
    ProfileManger
}
// Import necessary dependencies
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHref } from 'react-router-dom';

// Define the Breadcrumb component
export default function Breadcrumb() {

    const arbLangLocation = {
        home: "الرئيسية",
        profile: "الملف الشخصى",
        edit: "تعديل",
        auctions: "المزادات",
        deals: "الصفقات",
        wallet: "المحفظة",
        ['published-auctions']: "المزادات المنشورة",
        ['shared-auctions']: "مزادات شاركت بها",
        ['favorite-auctions']: "المزادات المفضلة",
        ['add-auction']: "إضافة مزاد",
        ['change-password']: "تغير كلمة المرور",
        ['auctions-awaiting-publication']: "مزادات فى انتظار النشر"
    }
    // Set initial state of the `getLocation` variable to the current window location pathname
    const [getLocation, setLocation] = useState(window.location.pathname);

    // Get the current location using the `useHref` hook
    const location = useHref();

    // Split the `getLocation` variable into an array called `improvePathname`
    const improvePathname = getLocation.split('/');


    // Update the `getLocation` state variable when the `location` variable changes
    useEffect(() => {

        setLocation(window.location.pathname);

        // Clean Up
        return _ => { };

    }, [location]);

    // Render the breadcrumb navigation and login section
    return (

        <div className="flex rounded-[5px] h-[50px] px-[60px] shadow-sm w-full bg-[var(--dark-gray-light)] mb-10">

            <div className="container flex items-center justify-between w-full">

                <div className="text-[14px] font-[600]">

                    <Link to="/" className='text-[var(--primary)]'>{arbLangLocation.home}</Link>

                    {/* Render pathnames using the `improvePathname` variable */}
                    {improvePathname.map((path, index) => {

                        // If the current `path` element is not empty:
                        return path.length ? (

                            // Wrap the JSX element in a <Fragment> component to group elements together
                            <Fragment key={index}>

                                {/* Add a forward slash with horizontal padding */}
                                <span className="px-1 text-[var(--primary)]">/</span>

                                {/* Create a clickable link to the URL constructed by concatenating a forward slash with the current `path` element */}
                                <Link to={path === (improvePathname[improvePathname.length - 1]) ? window.location.path : "/" + path} className={`text-[var(--primary)] text-[12px] sm:text-[16px] inline-block`}>

                                    {/* Replace any hyphens in the `path` element with spaces */}
                                    {arbLangLocation[path] || path}

                                </Link>

                            </Fragment>

                        ) : (
                            // If the current `path` element is empty, return null
                            null
                        );

                    })}

                </div>

            </div>

        </div>

    )
}
import { useEffect, useRef, useState } from "react";

const useDataGetter = () => {
    const first_inputRef = useRef();
    const second_inputRef = useRef();
    const thired_inputRef = useRef();
    const fourth_inputRef = useRef();
    const button_inputRef = useRef();
    const [value, setValue] = useState({
        "1": "",
        "2": "",
        "3": "",
        "4": "",
    });

    const [counter, setCounter] = useState(20);

    const handleChange = (e, next_sepling) => {

        const inputValue = e.target.value;

        if (inputValue.length) {

            next_sepling.current.focus();

            setValue(perv => ({ ...perv, [e.target.name]: e.target?.value }));
        }

    }

    useEffect(() => {

        const interval = setInterval(() => {

            setCounter(pervCount => pervCount - 1);

        }, 1000);

        if (counter <= 0) {

            clearInterval(interval);

        }

        // Clean up
        return () => clearInterval(interval);

    }, [counter]);

    return { first_inputRef, second_inputRef, thired_inputRef, fourth_inputRef, button_inputRef, counter, setCounter, handleChange, value };
}

export {
    useDataGetter
}
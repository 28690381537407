import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Origins {


    addOrigin(data) {

        return RequestManager.post(`${baseUrl}auctions/sub-auction/store`, data, true)

            .then(response => {

                return Swal.success('شكرا', response.data?.message).then(_ => sessionStorage.setItem('origins-key', 1)).then(_ => window.location.reload());

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }
    updateOrigin(data, id, navigate) {

        const updatedData = { ...data };

        const images = updatedData?.images?.filter(item => typeof item !== 'string');
        const old_images = updatedData?.images?.filter(item => typeof item === 'string');

        updatedData.images = images;
        updatedData.old_images = old_images;

        return RequestManager.update(`${baseUrl}auctions/sub-auction/update/${id}`, updatedData, null, true)

            .then(response => {

                return Swal.success('تم بنجاح!', response.data?.message).then(_ => navigate('/auctions-awaiting-publication'))

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

    deleteOrigin(auctionId, navigate) {

        return RequestManager.delete(`${baseUrl}auctions/sub-auction/delete/${auctionId}`)

            .then(response => {

                return Swal.success('تم بنجاح!', response.data?.message).then(resp => navigate('/auctions'));

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

}

export {
    Origins
}
import { Dialog } from 'primereact/dialog';
import { Link } from "react-router-dom";
import { useDataGetter } from "./data";


export default function ConfiremCodeForm({ visible, setVisible, handleSubmit, imageUrl, title, description, resendFunc }) {

    const {
        first_inputRef,
        second_inputRef,
        thired_inputRef,
        fourth_inputRef,
        button_inputRef,
        counter,
        setCounter,
        handleChange,
        value
    } = useDataGetter();

    return (

        <Dialog header="" visible={visible} className='lg:w-[50vw]' onHide={() => setVisible(false)}>

            <div className="flex items-center justify-center mb-5">

                <img src={require(`../../assets/images/${imageUrl}`)} />

            </div>

            <h1 className="text-[#3C3C3C] text-[30px] text-center font-medium mb-2">{title}</h1>

            <p className="text-[#7C7C7C] font-medium text-center mb-8"> {description}</p>

            <div>

                <div className="flex items-center justify-center mb-20" style={{ direction: "ltr" }}>

                    <input type="text" name='1' ref={first_inputRef} onChange={e => handleChange(e, second_inputRef)} maxLength={1} autoFocus className="border-2 border-[#34D1BA] transition text-center rounded-[5px] me-5 flex items-center justify-center w-[50px] h-[50px] text-[25px]" />
                    <input type="text" name='2' ref={second_inputRef} onChange={e => handleChange(e, thired_inputRef)} maxLength={1} className="border-2 border-[#34D1BA] transition text-center rounded-[5px] me-5 flex items-center justify-center w-[50px] h-[50px] text-[25px]" />
                    <input type="text" name='3' ref={thired_inputRef} onChange={e => handleChange(e, fourth_inputRef)} maxLength={1} className="border-2 border-[#34D1BA] transition text-center rounded-[5px] me-5 flex items-center justify-center w-[50px] h-[50px] text-[25px]" />
                    <input type="text" name='4' ref={fourth_inputRef} onChange={e => handleChange(e, button_inputRef)} maxLength={1} className="border-2 border-[#34D1BA] transition text-center rounded-[5px] me-5 flex items-center justify-center w-[50px] h-[50px] text-[25px]" />

                </div>

                <div className="flex items-center justify-center">

                    <button ref={button_inputRef} onClick={_ => handleSubmit(value)} className="w-[250px] text-center p-2 m-auto rounded-[5px] text-white text-[15px] font-medium bg-[#34D1BA]" type="submit">تاكيد الكود</button>

                </div>

                <div className="flex items-center justify-between m-auto mt-3 w-[250px]">

                    <div className="flex items-center">

                        <p className="me-2 text-[9px] font-medium">لم يتم الارسال ؟</p>

                        <Link onClick={_ => {

                            counter === 0 && setCounter(20);
                            counter === 0 && resendFunc();

                        }} className={`text-[#34D1BA] text-[9px] font-medium underline transition ${counter !== 0 ? "opacity-50 cursor-not-allowed" : "opacity-100"}`}>ارسل مجددا</Link>

                    </div>

                    <div className="w-[20px] h-[20px] text-[10px] rounded-full bg-[#34D1BA] flex items-center justify-center text-white font-medium">
                        {counter}
                    </div>

                </div>

            </div>

        </Dialog>

    )
}

import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Swal from "sweetalert2";

const uploadImageByLimt = (maxLength, inputId, allowedTypesFiles, notAllowdFilesError) => {

    const input = document.getElementById(inputId);

    input.addEventListener("change", function (e) {
        var files = e.target.files;
        var allowedTypes = allowedTypesFiles; // Specify the allowed image file types

        // Check if the number of selected files exceeds the limit
        if (files.length > maxLength) {

            Swal.fire({
                icon: "error",
                text: `Please select a maximum of ${maxLength} image files.`
            });
            this.value = null; // Clear the selected files
            return;
        }

        // Iterate through the selected files and check their types
        for (var i = 0; i < files.length; i++) {

            if (!allowedTypes.includes(files[i].type)) {
                Swal.fire({
                    icon: "error",
                    text: notAllowdFilesError
                });
                this.value = null; // Clear the selected files
                return;
            }
        }


    });
}

const dateFormat = (currentDate) => {

    const date = new Date(currentDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;

}

export default class SwalControlar {

    constructor() {

    }

    success(title, message) {

        return Swal.fire({
            icon: 'success',
            title: title || 'حسنا...',
            text: message,
        })

    }

    warning(title, message) {

        return Swal.fire({
            icon: 'warning',
            title: title || 'تحذير...',
            text: message,
        })

    }

    rejected(title, message) {

        return Swal.fire({
            icon: 'error',
            title: title || 'خطأ',
            text: message,
        });

    }
};

const fireSwal = (icon, title, text, moreOptions) => {
    return Swal.fire({
        icon,
        title,
        text,
        ...moreOptions
    });
}

function convertDate(dateString, type) {
    // Convert the input string to a Date object
    const date = new Date(dateString);

    // Define the Arabic names for days and months
    const arabicDays = ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"];
    const arabicMonths = ["محرّم", "صفر", "ربيع الأول", "ربيع الثاني", "جمادى الأولى", "جمادى الآخرة", "رجب", "شعبان", "رمضان", "شوال", "ذو القعدة", "ذو الحجة"];

    // Extract the day, month, and year from the Date object
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Format the result string
    const result = `${type === 'start' ? "يبدأ" : "ينتهي"} ${arabicDays[date.getDay()]} ، ${day} ${arabicMonths[month]} ، ${year}هـ`;

    return (
        <div className='flex items-center mb-4'>

            <div className='min-w-[21px] h-[21px] flex items-center justify-center bg-[#F0F0F0] rounded-[3px] me-3'>

                <FontAwesomeIcon icon={faClock} className='text-[var(--secondary-text-color)] text-[13px]' />

            </div>

            <h6 className='font-medium text-[11px]'>

                <span className='text-[var(--gray-color)]'> {result} </span>

            </h6>

            <h6 className='text-[var(--secondary-text-color)] font-medium text-[11px] sm:text-[13px] sm:ms-4'>
                {dateString} م
            </h6>

        </div>
    );
}

class SecureRequest {

    constructor(token) {

        this.token = token

    }

    auth(asFormData) {

        return {

            Authorization: `Bearer ${localStorage.getItem('token')}`,

            'Content-Type': asFormData ? "multipart/form-data" : "application/json"

        }

    }

    post(url, data, asFormData) {

        return axios({

            method: 'post',

            url: url,

            data: data,

            headers: this.auth(asFormData)

        })

    }

    get(url) {

        return axios({

            method: 'get',

            url: url,

            headers: this.auth()

        })

    }

    delete(url, data) {

        return axios({

            method: 'delete',

            url: url,

            data: data,

            headers: this.auth()

        })

    }

    update(url, data, method, asFormData) {

        return axios({

            method: method || 'post',

            url: url,

            data: data,

            headers: this.auth(asFormData),

        })

    }


}

const cities = ['جدة', 'المدينة المنورة', 'جيزان', 'خميس مشيط', 'مكة المكرمة', 'الرياض', 'شرورة', 'نجران', 'الطايف', 'الدمام', 'الخبر', 'الجبيل', 'الاحساء',
    'حائل', 'القصيم', 'عرعر', 'الجوف', 'ينبع', 'ابها', 'حفر الباطن', 'الباحة', 'تبوك'];

const handleChange = (e, setState) => {
    const name = e.target.name;
    const value = e.target.value;
    return setState(perv => ({ ...perv, [name]: value }));
}

const userPermissions = user => {

    const userRole = user?.role || JSON.parse(sessionStorage.getItem('user'))?.role;

    return userRole === 'Member';

}

const handleDelete = (callback) => {

    Swal.fire({
        title: 'هل انت متأكد',
        text: "ستقوم بالحذف",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم',
        cancelButtonText: 'لا'
    }).then((result) => {

        if (result.isConfirmed) callback();

    })

}

const getSelectedOption = (list, optionKey, value) => {
    return list?.filter(item => item[optionKey] == value)[0];
}

const auctionselectedType = [
    'إفتراضي',
    'حضوري',
    'إفتراضي حضوري'
];

const isLogin = _ => {

    const getUrlToken = window.location.href.slice(window.location.href.indexOf('token')).slice(6);

    if (getUrlToken) {

        localStorage.setItem('token', getUrlToken);

        return true;

    }

    const token = localStorage.getItem('token');

    return token ? true : false;
}

const handleToggleFullScreen = (elem) => {

    elem.preventDefault();

    if (elem.target.requestFullscreen) {

        elem.target.requestFullscreen();

    } else if (elem.target.msRequestFullscreen) {

        elem.target.msRequestFullscreen();

    } else if (elem.target.mozRequestFullScreen) {

        elem.target.mozRequestFullScreen();

    } else if (elem.target.webkitRequestFullscreen) {

        elem.target.webkitRequestFullscreen();

    } else {

        Swal.fire("Sorry, your browser is too old and doesn't support fullscreen :-(");

    }

};

// Handle User Logout
const handleLogOut = (response, userWant) => {

    if (response?.status == 401 || userWant) {

        window.localStorage.clear();

        window.sessionStorage.clear();

        return window.location.href = "https://abram.com.sa/";

    }

}
const numberFormat = number => {
    let formattedNumber = number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedNumber;
}
export {
    uploadImageByLimt,
    SecureRequest,
    handleChange,
    cities,
    dateFormat,
    auctionselectedType,
    getSelectedOption,
    convertDate,
    fireSwal,
    isLogin,
    handleToggleFullScreen,
    userPermissions,
    handleLogOut,
    numberFormat,
    handleDelete
}
import { DataView } from 'primereact/dataview';
import NotFound from '../../pages/NotFound/NotFound';


export default function DataTable({ data, gridItem, countOfRows = 9, messageText, classNames }) {

    return (
        <div className="card">

            <DataView value={data} paginator={data?.length > countOfRows} className={classNames} rows={countOfRows || 10} emptyMessage={<NotFound classNames={'!h-fit'} title={messageText || 'لا يوجد'} hideButton={true} />} itemTemplate={gridItem} layout={'grid'} />

        </div>
    )
}

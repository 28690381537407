// importing require modules
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { MainContent } from "../components";

// User Routes
import {
    Loader,
    Login,
    EditAuction,
    Profile,
    Analytics,
    Auctions,
    AddAuction,
    ChangePassword,
    SharedActuion,
    FavoriteAuction,
    AuctionsAwaitingPublication,
    StepToCreateWallet,
    Wallet,
    NotFound,
    Deals
} from './data';

export default function routes(isMemmber, isExpanded, isRenderHeader, setIsRenderLayouts, isLoggedIn) {

    const notFound = <NotFound setIsRenderLayouts={setIsRenderLayouts} notRenderLayout={true} />

    return (
        // Prevent loading errors with fallback Loader
        <Suspense fallback={<Loader />}>

            <MainContent isExpanded={isExpanded} isRenderHeader={isRenderHeader}>

                {/* Route configuration */}
                <Routes>

                    {/* Start User Routes */}

                    <Route path="" element={isLoggedIn ? <Analytics /> : notFound} />

                    <Route path="profile" element={isLoggedIn ? <Profile /> : notFound} />

                    <Route path="change-password" element={isLoggedIn ? <ChangePassword /> : notFound} />

                    <Route path="add-auction" element={isMemmber && isLoggedIn ? <AddAuction /> : notFound} />

                    <Route path="auctions" element={isMemmber && isLoggedIn ? <Auctions /> : notFound} />

                    <Route path="deals" element={isLoggedIn ? <Deals /> : notFound} />

                    <Route path="auctions-awaiting-publication" element={isMemmber && isLoggedIn ? <AuctionsAwaitingPublication /> : notFound} />

                    <Route path="auctions-awaiting-publication/edit" element={isLoggedIn ? <EditAuction isDisapled={false} /> : notFound} />

                    <Route path="published-auctions/view" element={isLoggedIn ? <EditAuction isDisapled={true} /> : notFound} />

                    <Route path="shared-auctions" element={isLoggedIn ? <SharedActuion /> : notFound} />

                    <Route path="favorite-auctions" element={isLoggedIn ? <FavoriteAuction /> : notFound} />

                    <Route path="create-wallet" element={isLoggedIn ? <StepToCreateWallet /> : notFound} />

                    <Route path="wallet" element={isLoggedIn ? <Wallet /> : notFound} />

                    <Route path="*" element={notFound} />

                    {/* End Of Route configuration */}
                </Routes>

            </MainContent>

        </ Suspense>
    );
}

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isLogin } from '../../assets/js/utils';

export default function NotFound({ title, hideButton, classNames, setIsRenderLayouts, notRenderLayout }) {

    const isLoggedIn = isLogin();

    useEffect(() => {

        notRenderLayout && setIsRenderLayouts(false);

        // Clean Up
        return _ => { };

    }, [notRenderLayout]);

    return (
        <div className={`h-[83vh] flex items-center justify-center flex-col ${classNames}`}>

            <img src={require('../../assets/images/not-found.gif')} alt='' loading='lazy' width={''} height={''} className='mb-3' />

            <h1 className='text-[var(--secondray-color)] font-medium text-[30px] mb-6'>{title || 'الصفحة غير موجودة'}</h1>

            {
                !hideButton ? <Link to={isLoggedIn ? '/' : 'https://abram.com.sa/login'} onClick={_ => setIsRenderLayouts(true)} className='bg-[var(--primary)] text-white p-2 px-12 rounded-[5px]'>الرجوع لصفحة الرئيسية</Link> : null
            }

        </div>
    )
}

import { DataTable } from 'primereact/datatable';
import { Fragment, useRef } from 'react';
import TableHeader from '../TableHeader/TableHeader';
import { useDataGetter, FilterFields, data } from './data';
import { useReactToPrint } from "react-to-print";

export default function RenderTable({
    columns,
    list,
    saveName,
    classNames
}) {
    const {
        filters,
        onGlobalFilterChange,
        globalFilterValue,
    } = useDataGetter(list);

    const table = useRef();

    const HandleExport = useReactToPrint({
        content: () => table.current,
        documentTitle: "Userdata",
    });

    return (
        <Fragment>

            <TableHeader
                globalFilterValue={globalFilterValue}
                columns={columns}
                saveName={saveName}
                list={list}
                HandleExport={HandleExport}
                onGlobalFilterChange={onGlobalFilterChange}
            />
            <div ref={table}>

                <DataTable
                    value={list}
                    filters={filters}
                    globalFilterFields={FilterFields}
                    id="table"
                    paginator={list?.length > 10}
                    rows={10}
                    dataKey="id"
                    emptyMessage={<img src={require('../../assets/images/not-found.gif')} alt='' width={''} height={''} className='m-auto max-w-full' />}
                    className={`px-2 lg:px-8 ${classNames}`}
                >
                    {data(columns)}
                </DataTable>
            </div>
        </Fragment >
    );
}

import { dateFormat, handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Auctions {

    fetchAuctions(state) {

        return RequestManager.get(`${baseUrl}auctions/main-auction`)

            .then(response => {

                return state(response.data?.MainAuctionServices);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

    fetchAuctionsNames(state) {

        return RequestManager.get(`${baseUrl}auctions/main-auction`)

            .then(response => {

                const PandingAuctions = response.data?.MainAuctionServices?.filter(item => item?.status === "PENDING");

                return state(PandingAuctions);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }
    fetchDeals(state) {

        return RequestManager.get(`${baseUrl}deal/all`)

            .then(response => {

                return state(response.data?.DealsServices);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }
    getSingleAuction(state, id) {

        return RequestManager.get(`${baseUrl}auctions/main-auction/edit/${id}`)

            .then(response => {

                return state(response.data?.MainAuctionServices);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

    addAuction(data, setActiveIndex) {

        const newData = { ...data, start_date: dateFormat(data.start_date), end_date: dateFormat(data.end_date) };

        return RequestManager.post(`${baseUrl}auctions/main-auction/store`, newData, true)

            .then(response => {

                return Swal.success('شكرا', response.data?.message).then(resp => setActiveIndex(1));

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

    editAuctions(data, id) {

        // const newData = { ...data, StartDate: dateFormat(data.StartDate), EndDate: dateFormat(data.EndDate) };

        // Update Date Format
        if (data.start_date) {

            data.start_date = dateFormat(data.start_date);

        }

        if (data.end_date) {

            data.end_date = dateFormat(data.end_date);

        }

        return RequestManager.update(`${baseUrl}auctions/main-auction/update/${id}`, data, 'post', true)

            .then(response => {

                return Swal.success('تم بنجاح!', response.data?.message);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }


    subscribedAuctions(state) {

        return RequestManager.get(`${baseUrl}bidder/management/subscription`)

            .then(response => {

                return state(response.data?.BidderSubscriptionServices);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }
    favouritesAuctions(state) {

        return RequestManager.get(`${baseUrl}user-activity/favorite`)

            .then(response => {

                return state(response.data?.FavoriteServices);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }
    deleteAuction(auctionId, navigate) {

        return RequestManager.delete(`${baseUrl}auctions/main-auction/delete/${auctionId}`)

            .then(response => {

                return Swal.success('تم بنجاح!', response.data?.message).then(_ => navigate("/auctions"));

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }
    updateStatusAuction(data, auctionId) {

        return RequestManager.update(`${baseUrl}deal/update-status/${auctionId}`, data, 'post')

            .then(response => {

                return Swal.success('تم بنجاح!', response.data?.message).then(resp => window.location.reload());

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

    requestToPublished(id) {

        return RequestManager.update(`${baseUrl}auctions/main-auction/update/status/${id}`, null, 'put')

            .then(response => {

                return Swal.success('تم بنجاح!', response.data?.message).then(resp => window.location.href = "/auctions-awaiting-publication");

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            })

    }

}

export {
    Auctions
}
import React, { Fragment } from 'react'
import Input from '../Input'

export default function RenderInputWithLabel({ label, type, placeholder, defaultValue, value, name, onChange, disabled }) {

  return (

    <Fragment>

      <label className='mb-2 block font-medium text-[var(--secondary)] text-[18px]'>{label}</label>

      <Input onChange={onChange} disabled={disabled} value={value} name={name} inputType={type} defaultValue={defaultValue} placeholder={placeholder} />

    </Fragment>

  )
}
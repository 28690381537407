import { faBell, faUser } from '@fortawesome/free-regular-svg-icons'
import { faBars, faClose, faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Notficaitions } from '../../components'
import { useDataGetter } from './data';


export default function Header({ isExpanded, setIsExpanded }) {

    const {
        logoutVisible,
        visible,
        setVisible,
        setLogoutVisible,
        data,
        logOut
    } = useDataGetter();

    return (
        <header className='bg-[var(--dark-gray)] fixed top-0 w-full z-[1500] p-3 sm:px-20 flex items-center justify-between mb-5'>

            <div onClick={e => e.stopPropagation()}>
                <div onClick={_ => setIsExpanded(!isExpanded)} className='flex lg:hidden items-center justify-center me-5 w-[35px] h-[35px] rounded-[5px] bg-[var(--dark-gray-light)]'>
                    <FontAwesomeIcon icon={isExpanded ? faClose : faBars} className='text-[var(--icon)] text-[20px]' />
                </div>
            </div>

            <div onClick={e => {
                e.stopPropagation();
                setLogoutVisible(perv => !perv);
            }} className='flex items-center'>

                <a target='_blank' href={'https://abram.com.sa/'} className='flex items-center justify-center me-5 w-[35px] h-[35px] rounded-full bg-[var(--dark-gray-light)]'>
                    <FontAwesomeIcon icon={faHouse} className='text-[var(--icon)] text-[18px]' />
                </a>

                <div className='flex items-center justify-center me-5 w-[35px] h-[35px] rounded-full bg-[var(--dark-gray-light)]'>
                    <FontAwesomeIcon icon={faUser} className='text-[var(--icon)] text-[18px]' />
                </div>

                <div onClick={e => {
                    e.stopPropagation();
                    setVisible(perv => !perv);
                }} className='relative flex items-center justify-center w-[35px] h-[35px] rounded-full bg-[var(--dark-gray-light)]'>

                    <FontAwesomeIcon icon={faBell} className='text-[var(--icon)] text-[18px]' />

                    {
                        data?.totalNullReadAt
                            ?
                            <div className='w-[10px] h-[10px] rounded-full bg-[red] absolute -top-1 right-0'></div>
                            :
                            null
                    }

                </div>

            </div>

            <div onClick={e => e.stopPropagation()} className={`${logoutVisible ? "opacity-100 visible scale-100" : "opacity-0 invisible scale-0"} overflow-y-auto transition w-fit absolute bg-white left-20 top-16 p-3 border border-[var(--secondary)] rounded-[10px]`}>

                <h5 className='text-center cursor-pointer text-[var(--secondary)]' onClick={logOut}>تسجيل إلخروج</h5>

            </div>

            <Notficaitions visible={visible} data={data} />

        </header>
    )
}

import { RequestManager, baseUrl, Swal } from "../data";

class Auth {

    constructor(setLoader) {

        this.setLoader = setLoader;

    }

    postLogin(data) {

        return RequestManager.post(`${baseUrl}auth/login`, data, false).then(response => {

            // append Data to Localstorage
            localStorage.setItem('token', response.data);
            Swal.success(null, response.data?.message || `تم تسجيل الدخول بنجاح`);

        })
            .catch(error => {

                return Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`);

            })

    }

    postLogout() {

        return RequestManager.post(`${baseUrl}auth/logout`).then(response => {

            return;

        })
            .catch(error => error);
    }

}

export {
    Auth
}
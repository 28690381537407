import { faAngleRight, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PanelMenu } from 'primereact/panelmenu';
import { items } from "./data"
import { Link, NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../../components/AppContext/AppContext';


export default function MnueMainContent({ isExpanded }) {

    const linkStyle = `w-full rounded-[6px] flex items-center p-3 !text-black font-medium`;

    const isHasWallet = JSON.parse(sessionStorage.getItem('user'))?.status_virtAcc;

    const { user } = useContext(AppContext);

    return (

        <div className={`main-menu-content relative h-full overflow-y-auto pb-10`}>

            <ul className=''>

                <li className={`px-2`}>

                    <NavLink to={''} className={linkStyle} end>

                        <FontAwesomeIcon icon={faChartLine} />

                        <span className='ms-4'>التحليلات</span>

                    </NavLink>

                </li>

                <li className={`px-2`}>

                    <NavLink to={`${isHasWallet ? "/wallet" : "/create-wallet"}`} className={linkStyle} end>


                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M7.5 2.25H2.25V7.5H7.5V2.25Z" stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.75 2.25H10.5V7.5H15.75V2.25Z" stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.75 10.5H10.5V15.75H15.75V10.5Z" stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 10.5H2.25V15.75H7.5V10.5Z" stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        <span className='ms-4'>المحفظة</span>

                    </NavLink>

                </li>

                <li className={`px-2`}>

                    <PanelMenu model={items(user)} multiple={true} className="w-full" />

                </li>

                {/* <Link to={'https://abram.com.sa/'} target='_blank' className={`px-2 transition fixed ${isExpanded ? 'right-1' : 'right-[-300px]'} bottom-3 w-[285px]`}>

                    <div className='flex items-center justify-between w-full'>

                        <div className={`rounded-[6px] text-white justify-between w-full flex p-3 items-center bg-[#202831]`}>

                            <div className='relative'>

                                <img src={require('../../../../assets/images/layout-logo.png')} className={`w-[40px] h-[40px] rounded-full`} />

                            </div>

                            <div className='text-[12px]'>

                                <h2 className='text-white font-medium mb-1'>إبرام للمزادات</h2>

                                <p className='text-[#CFCFCF]'>الذهاب إلى الصفحة الرئيسية</p>

                            </div>

                            <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>

                        </div>

                    </div>

                </Link> */}

            </ul>
        </div >
    )
}

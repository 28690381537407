// ##Lazy Function To Load Page Easly Importing From React 
import { lazy } from "react";


const AuctionsAwaitingPublication = lazy(() => import('../pages/user/AuctionsAwaitingPublication'));
const FavoriteAuction = lazy(() => import('../pages/user/FavoriteAuction'));
const ChangePassword = lazy(() => import('../pages/user/ChangePassword'));
const SharedActuion = lazy(() => import('../pages/user/SharedActuion'));
const AddAuction = lazy(() => import('../pages/user/AddAuction'));
const Analytics = lazy(() => import('../pages/user/Analytics'));
const Auctions = lazy(() => import('../pages/user/Auctions'));
const Profile = lazy(() => import('../pages/user/Profile'));
const EditAuction = lazy(() => import('../pages/user/EditAuction'));
const Login = lazy(() => import('../pages/Login'));
const Loader = lazy(() => import('../pages/Loader'));
const Deals = lazy(() => import('../pages/user/Deals'));
const StepToCreateWallet = lazy(() => import('../pages/user/CreateWallet'));
const Wallet = lazy(() => import('../pages/user/Wallet'));
const NotFound = lazy(() => import('../pages/NotFound'));



export {
    Loader,
    Login,
    EditAuction,
    Profile,
    Analytics,
    Auctions,
    AddAuction,
    ChangePassword,
    SharedActuion,
    FavoriteAuction,
    AuctionsAwaitingPublication,
    Deals,
    StepToCreateWallet,
    Wallet,
    NotFound,
}
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleToggleFullScreen } from "../../assets/js/utils";
import Swal from "sweetalert2";


const renderImagesUpload = (imagesUpload, setImagesUpload, typeAsImage, setImagesAsInputFiles, isDisabled) => {

    return imagesUpload.map((item, index) => {

        return (

            <div key={index} className={`flex items-center flex-wrap my-5 rounded-[5px] bg-white pe-2`}>

                {
                    typeAsImage
                        ?

                        <img
                            src={item.url || require('../../assets/images/default_user.png')}
                            onClick={handleToggleFullScreen}
                            onError={(e) => {
                                e.target.src = require('../../assets/images/default_user.png');
                            }}
                            className="ms-1 w-[30px] sm:w-[46px] h-[40px] sm:h-[60px] rounded-tr-[5px] rounded-br-[5px] object-contain cursor-pointer"
                            alt=""
                        />

                        :

                        <div className="w-[30px] sm:w-[50px] h-[30px] sm:h-[50px] flex items-center justify-center">

                            <FontAwesomeIcon icon={faFilePdf} className="text-[25px] text-[var(--primary)]" />

                        </div>

                }
                <div className="flex-1 p-2 mx-2 sm:mx-7">

                    <div className="flex items-center mb-1">

                        <h5 className="text-[12px] sm:text-[15px] font-medium me-2">{item.status === "panding" ? "جاري التحميل" : "تم التحميل"}</h5>

                        <p className="text-[12px] text-[#4E4E4E]">{item.name}</p>

                    </div>

                    <div className="flex items-center justify-between">

                        <div className="flex-1 h-[6px] rounded-full bg-[#F5F5F5] transition">
                            <div className="h-full rounded-full bg-[#707882]" style={{ width: `${item.progress}%`, transition: "0.3s  ease" }}></div>
                        </div>

                        <p className="text-[12px] font-medium w-[40px] transition ms-5">{item.progress}%</p>

                    </div>

                </div>

                <div className="flex items-center justify-center sm:justify-start mb-3 sm:mb-0 w-full sm:w-fit">

                    {
                        !isDisabled
                            ?
                            <div className="w-[30px] sm:w-[45px] h-[30px] sm:h-[45px] me-3 rounded-[5px] flex items-center justify-center bg-[#ff000026]">

                                <FontAwesomeIcon
                                    onClick={_ => handleRemoveImage(imagesUpload, setImagesUpload, setImagesAsInputFiles, index)}
                                    icon={faClose}
                                    className="text-[18px] sm:text-[25px] text-[red]"
                                />

                            </div>
                            :
                            null
                    }

                    <div className="w-[30px] sm:w-[45px] h-[30px] sm:h-[45px] rounded-[5px] flex items-center justify-center bg-[#DCFFDF]">

                        {
                            item.status === "pending"
                                ?
                                <span className="loader"></span>
                                :
                                <FontAwesomeIcon icon={faCheck} className="text-[18px] sm:text-[25px] text-[#54D989] cursor-default" />
                        }

                    </div>

                </div>

            </div>

        )
    })
}

const handleRemoveImage = (imagesUpload, setImagesUpload, setImagesAsInputFiles, index) => {

    const newImages = old => old.filter((image, i) => index != i);

    setImagesUpload(perv => newImages(perv));

    if (!imagesUpload[index]?.old) {

        setImagesAsInputFiles(perv => newImages(perv));

    }

    return;

}


const handelChangeImageFileInput = (
    e,
    setImagesUpload,
    setActiveIndex,
    maxLength,
    setImagesAsInputFiles,
    inputRef
) => {

    const files = Array.from(e.target.files).map(item => {
        return {
            status: 'pending',
            name: item.name,
            progress: 0,
            url: URL.createObjectURL(item)
        };
    });

    setImagesAsInputFiles(prev => [...prev, ...e.target.files]);

    setImagesUpload(prev => {

        const totalImagesLength = prev.length + files.length;

        if (maxLength == 1 && files.length === 1 && prev.length === 0) {

            return files;

        }

        if (totalImagesLength > maxLength) {

            Swal.fire({
                icon: 'error',
                text: `Please select a maximum of ${maxLength} image files.`
            });

            inputRef.current.value = [];

            return prev;

        } else {

            return [...prev, ...files];

        }
    });

    setActiveIndex(0);
};
export {
    handelChangeImageFileInput, renderImagesUpload
}
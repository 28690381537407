import RenderSection from '../RenderSection'
import RenderInputWithLabel from '../RenderInputWithLabel'
import Textarea from '../Textarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function AuctionCard({ editHref, item }) {

    const auction = item;

    return (
        <div data-aos="fade-up" className='relative mb-10 transition-all'>

            <RenderSection classNames={'!p-8 !bg-[#F3F4F6]'}>

                <Link to={editHref} className='bg-[var(--secondary)] p-1 px-10 absolute left-3 top-3 rounded-full text-white'>تعديل</Link>

                <div className='grid grid-cols-12 gap-5'>

                    <div className='col-span-12 md:col-span-6'>

                        <RenderInputWithLabel
                            disabled={true}
                            label={'اسم المزاد'}
                            type={'text'}
                            defaultValue={auction?.auction_title} />

                    </div>

                    <div className='col-span-12 md:col-span-6'>

                        <RenderInputWithLabel
                            label={'عدد الأصول'}
                            type={'number'}
                            defaultValue={auction?.asset_number}
                            disabled={true}
                        />

                    </div>

                    <div className='col-span-12'>

                        <label className='mb-2 block font-medium text-[var(--secondary)] text-[18px]'>اكتب وصف للمزاد</label>

                        <Textarea
                            disabled={true}
                            classNames={'!h-[150px]'}
                            defaultValue={auction?.auction_description} />
                    </div>

                </div>

            </RenderSection>

            <Link to={editHref} className='w-[42px] h-[42px] cursor-pointer rounded-full flex items-center justify-center bg-[var(--secondary)] text-white absolute left-0 -bottom-8 transition-all'>

                <FontAwesomeIcon icon={faAngleDoubleDown} />

            </Link>

        </div>
    )
}

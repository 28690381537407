import React, { Fragment } from 'react'
import { MnueMainContent, MnueHeader } from './components';
import "./components/index.css";

export default function MainPanel({ isExpanded }) {

    return (
        <Fragment>

            <div onClick={e => e.stopPropagation()} className={`main-menu top-[60px] z-[9999999] ${isExpanded ? "right-0" : "right-[-300px]"} bg-white py-10 shadow-lg menu-fixed menu-accordion`} >

                <MnueHeader />

                <MnueMainContent isExpanded={isExpanded}></MnueMainContent>

            </div>

        </Fragment>
    )
}

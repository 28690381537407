import { handleToggleFullScreen } from '../../../../assets/js/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';

export default function MnueHeader() {

    const userInfo = JSON.parse(sessionStorage.getItem('user'));

    return (
        <div className={`w-full flex items-center justify-center flex-col mb-10 transition-all`}>

            {
                userInfo?.img
                    ?
                    <img
                        onClick={handleToggleFullScreen}
                        src={userInfo?.img || require('../../../../assets/images/default.png')}
                        className='mb-3 w-[40px] h-[40px] object-contain rounded-[10px] cursor-pointer'
                        onError={(e) => {
                            e.target.src = require('../../../../assets/images/default.png');
                        }}
                    />
                    :

                    <div className=' w-[40px] h-[40px] bg-[#D9D9D9] rounded-[10px] flex items-center justify-center mb-3'>

                        <FontAwesomeIcon icon={faUser} className='cursor-pointer' />

                    </div>
            }

            <h2 className='text-[var(--dark-text)] text-[20px] text-center mb-2'>{userInfo?.company_name}</h2>

            <h6 className='text-[10px] font-medium text-[var(--dark-text)]'>{userInfo?.role === "Member" ? "ناشر المزلد" : "مُزايد"}</h6>

        </div>
    )
}

import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class NotificationService {

    fetchNotifications(state) {

        return RequestManager.get(`${baseUrl}notifications`)

            .then(response => {

                return state(response.data);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            });

    }

    updateNotifications() {

        return RequestManager.update(`${baseUrl}notifications/read-at`, {}, 'put')

            .then(response => {

                return;

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `هناك خطأ برجاء المحاولة لاحقا`).then(_ => handleLogOut(error?.response));

            });

    }

}

export {
    NotificationService
}